<template>
  <div class="cameraOperationStatusText">
    <span 
      v-if="tooltip" 
      v-tooltip="tooltip"
      data-toggle="tooltip"
      data-placement="top"
      :class="statusClass"
    >{{ statusText }}</span>
    <span
      v-else
      :class="statusClass"
    >{{ statusText }}</span>
  </div>
</template>

<script>
export default {
  name: "CameraOperationSatatusText",
  props: {
    status: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  computed: {
    statusText: function () {
      let tmpStatus = this.status.toLowerCase();
      if (tmpStatus === 'notconfigured') {
        return this.status.slice(0, 3) + " " + this.status.slice(3);
      }
      return this.status;
    },
    statusClass: function () {
      let tmpStatus = this.status.toLowerCase();
      if (tmpStatus === 'prepared') {
        return 'green';
      }
      if (tmpStatus === 'notconfigured') {
        return 'green';
      }
      if (tmpStatus === 'unknown' || tmpStatus === 'failure') {
        return 'red';
      }
      return '';
    }
  }
}
</script>
